<template>
  <div>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <a-btn-add v-if="loanCount == 0" @click="makeDirLoan()" :title="'Создать  график'" :disabled="data.status == 2" :icon1="'fas fa-layer-group'" />
    <s-form-loan-schedule v-else-if="loanCount == 1" v-model="loan_schedule" :object="{ id: 42 }" class="pb-3" @validate="validate($event)" />
    <div v-else>
      Ошибка получения графика выплат по кредиту
      <a-btn-add @click="fetchLoan()" :title="'Обновить'" :disabled="data.status == 2" />
    </div>
  </div>
</template>

<script>
import { getAccess, popupMenu } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu],
  components: {},
  props: {
    category: Number,
    data: Object,
  },
  data() {
    return {
      loanDir: null,
      loanCount: -1,
      loan_schedule: null,
      loading: false,
    };
  },
  created() {
    if (this.data?.id) this.fetchLoan(this.data.id);
  },
  watch: {
    "data.id"(v) {
      if (v) this.fetchLoan(v);
    },
  },
  computed: {},
  methods: {
    async makeDirLoan() {
      if (this.loading) return;
      this.loading = true;

      let parent_id = this.data?.id;
      if (!parent_id) return;
      let data = { parent_name: "AccountingDocGettingLoan", parent_id, status: 1 };
      let response = await this.$axios.post("/accounting/dir/loan_payment_schedule", data);
      if (response.data.status == "ok") {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "График создан",
        });
        this.fetchLoan();
      }
    },
    async fetchLoan(id) {
      if (!id) id = this.data?.id || 0;
      if (id) {
        this.loading = true;

        this.loanCount = -1;
        let count = -1;
        let res = [];
        let params = { filters: { parent_id: id, deleted: 0 } };
        let resp = await this.$axios.get("/accounting/dir/loan_payment_schedule", { params });
        if (resp.data.status === "ok") {
          let data = resp.data.data;

          count = data.length;
          console.log(data, count);
          if (count > 0) {
            this.loanDir = data[0];
            res = this.loanDir.loan_schedule;
          }
        }
        this.loanCount = count;
        this.loan_schedule = res;
      }
      this.loading = !true;
    },
    validate(e) {
      console.log(e);
    },
  },
};
</script>
